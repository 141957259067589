import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28473af2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "Skill__Title" }
const _hoisted_3 = { class: "Skill__Description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_rjs = _resolveDirective("rjs")!

  return (_openBlock(), _createElementBlock("div", {
    class: "Skill__Wrapper",
    style: _normalizeStyle({ maxWidth: _ctx.getWidthPercent })
  }, [
    _withDirectives(_createElementVNode("img", {
      class: "Skill__Image",
      src: _ctx.dataSkill.icon
    }, null, 8, _hoisted_1), [
      [_directive_rjs, 3]
    ]),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.dataSkill.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.dataSkill.description), 1)
  ], 4))
}