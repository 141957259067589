import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e135560"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Skills__Wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "Skills__List" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skill = _resolveComponent("Skill")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      class: "Skills__Title",
      innerHTML: _ctx.dataSkills.title
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSkills.skills, (skill, index) => {
        return (_openBlock(), _createBlock(_component_Skill, {
          key: index,
          "data-skill": skill,
          columns: _ctx.dataSkills.columns,
          length: _ctx.dataSkills.skills.length
        }, null, 8, ["data-skill", "columns", "length"]))
      }), 128))
    ])
  ]))
}