import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf1043b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Content__Wrapper" }
const _hoisted_2 = { class: "Content__Background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutMe = _resolveComponent("AboutMe")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_Works = _resolveComponent("Works")!
  const _component_ProgramLanguages = _resolveComponent("ProgramLanguages")!
  const _component_Curve = _resolveComponent("Curve")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AboutMe, { class: "Content__Component" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Skills, {
        class: "Content__Component",
        "data-skills": _ctx.jsonDataWhatIDo
      }, null, 8, ["data-skills"]),
      _createVNode(_component_Skills, {
        class: "Content__Component",
        "data-skills": _ctx.jsonDataSpecialSkills
      }, null, 8, ["data-skills"]),
      _createVNode(_component_Works, { class: "Content__Component" }),
      _createVNode(_component_ProgramLanguages, { class: "Content__Component" })
    ]),
    _createVNode(_component_Curve, { color: '#220724' })
  ]))
}