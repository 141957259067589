
import jsonData from '@/assets/properties/about-me.json';
import IButton from '../../../shared/interfaces/button.interface';
import Button from '../../../shared/button/Button.vue';
import IAboutUs from '../../../shared/interfaces/about-us.interface';
import {Options, Vue} from "vue-class-component";

@Options({
  components: {
    Button,
  },
})
export default class AboutMe extends Vue {
  public dataAbout: IAboutUs = jsonData;
  public sectionTitle!: string;
  public sectionDescription!: string;
  public sectionButton!: IButton;

  created() {
    this.sectionTitle = this.dataAbout.title;
    this.sectionDescription = this.dataAbout.description;
    this.sectionButton = this.dataAbout.button;
  }
}
