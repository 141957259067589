
import { Prop } from 'vue-property-decorator'
import {Options, Vue} from 'vue-class-component';
import IButton from '../../shared/interfaces/button.interface';

@Options({})
export default class DialogPolicy extends Vue {
  @Prop({ default: () => false}) public active!: boolean;
  @Prop() public title!: string;
  @Prop() public content!: string;
  @Prop() public confirmText!: string;
  @Prop() public ctaText!: string;

  public close() {
    this.$emit("agreed");
  }
}
