import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "DialogPolicy_Overlay"
}
const _hoisted_2 = { class: "DialogPolicy_Modal" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "DialogPolicy_Title",
            innerHTML: _ctx.title
          }, null, 8, _hoisted_3),
          _createElementVNode("p", {
            class: "DialogPolicy_Content",
            innerHTML: _ctx.content
          }, null, 8, _hoisted_4),
          _createElementVNode("a", {
            class: "DialogPolicy_CTA",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, "Agree"),
          _createElementVNode("a", {
            class: "DialogPolicy_CTA DialogPolicy_CTA--cancel",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, "Not Agree")
        ])
      ]))
    : _createCommentVNode("", true)
}