
import {Options, Vue} from 'vue-class-component';
import { Prop } from 'vue-property-decorator'
import Button from '../../../../shared/button/Button.vue';
import IWork from '../../../../shared/interfaces/work.interface';

@Options({
  components: {
    Button,
  },
})
export default class Work extends Vue {
  @Prop() public index?: number;
  @Prop() public dataWork?: IWork;

  get isOdd() {
    const i: number = (this.index) ? this.index : 0;
    return i % 2;
  }
}
