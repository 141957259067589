import {createApp} from 'vue';
// Include Material Design Components
//import { MdDialog, MdDialogAlert, MdDialogConfirm, MdTabs, MdButton } from 'vue-material/dist/components';
//import 'vue-material/dist/vue-material.min.css';
//import 'vue-material/dist/theme/default.css';
import App from './App.vue';
// Include RetinaJs
import retina from 'retinajs';
import VueRetina from 'vue-retina';
// Include ParticlesJs
import VueParticles from 'vue-particles';
import VueGtag from 'vue-gtag';
// Google Analytics


const app = createApp(App, {

})
app.use(VueRetina, {retina});
app.use(VueParticles);
//app.use(MdDialog);
//app.use(MdDialogAlert);
//app.use(MdDialogConfirm);
//app.use(MdTabs);
//app.use(MdButton);
app.use(VueGtag, {
    config: { id: "UA-20077537-1" }
});
app.mount('#app')


//Vue.config.productionTip = false;

