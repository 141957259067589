
import {Options, Vue} from 'vue-class-component';
import jsonData from '@/assets/properties/footer.json';
import DialogPolicy from '@/components/shared/dialog/DialogPolicy.vue';
import IFooter from '../shared/interfaces/footer.interface';

@Options({
  components: {
    DialogPolicy,
  },
})
export default class Footer extends Vue {
  public footerData: IFooter = jsonData;
  public modalOpen = false;

  created() {
    const dateToday = new Date();
    const Year: number = dateToday.getFullYear();
    this.footerData.copyright = this.footerData.copyright.replace('%YEAR%', Year.toString());
  }
}
