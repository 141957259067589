<template>
    <vue-particles
            color="#e17c82"
            :particleOpacity="0.6"
            :particlesNumber="80"
            shapeType="circle"
            :particleSize="1"
            linesColor="#e17c82"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="0.7"
            :linesDistance="200"
            :moveSpeed="7"
            :hoverEffect="false"
            hoverMode="grab"
            :clickEffect="false"
            clickMode="push"
    >
    </vue-particles>
</template>

<script>
    export default {
        name: 'ParticlesJS',
    };
</script>

<style lang="scss">
    .particles-js-canvas-el {
            height: 600px !important;
    }
</style>
