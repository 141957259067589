
import {Vue, Options} from 'vue-class-component';
import Work from './components/Work.vue';
import IWorks from '../../../shared/interfaces/works.interface';
import jsonData from '@/assets/properties/works.json';

@Options({
  components: {
    Work,
  },
})
export default class Works extends Vue {
  public dataWorks: IWorks = jsonData;
}
