
import {Vue, Options} from 'vue-class-component';
import { Prop } from 'vue-property-decorator'
import ISkillComponent from '../../../shared/interfaces/skill-component.interface';
import Skill from './components/Skill.vue';

@Options({
  components: {
    Skill,
  },
})
export default class Skills extends Vue {
  @Prop() public dataSkills?: ISkillComponent;
}
