
import {Vue} from 'vue-class-component';
import ISkill from '../../../../shared/interfaces/skill.interface';
import {Prop} from "vue-property-decorator";

export default class Skills extends Vue {
  @Prop() public dataSkill?: ISkill;
  @Prop(Number) public length?: number;
  @Prop(Number) public columns?: number;

  get getWidthPercent(): string {
    return `${this.calcWidth}%`;
  }

  get calcWidth(): number {
    const columns: number = (this.columns) ? this.columns : 2;
    return (100 / columns) - columns;
  }
}
