import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b47fb78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Aboutme__Wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      class: "Aboutme__Title",
      innerHTML: _ctx.sectionTitle
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      class: "Aboutme__Description",
      innerHTML: _ctx.sectionDescription
    }, null, 8, _hoisted_3),
    _createVNode(_component_Button, { value: _ctx.sectionButton }, null, 8, ["value"])
  ]))
}