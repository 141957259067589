import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c6f6412"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "Header",
  class: "Header Header__Wrapper"
}
const _hoisted_2 = { class: "Logo__Wrapper" }
const _hoisted_3 = ["src", "title"]
const _hoisted_4 = { class: "Header__WorkTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticlesJS = _resolveComponent("ParticlesJS")!
  const _component_Curve = _resolveComponent("Curve")!
  const _directive_rjs = _resolveDirective("rjs")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_ParticlesJS),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("img", {
        class: "Logo__Image",
        src: './img/logo.png',
        title: _ctx.myName + ' ' + _ctx.mySurname
      }, null, 8, _hoisted_3), [
        [_directive_rjs, 3]
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.myTitle), 1)
    ]),
    _createVNode(_component_Curve, { color: '#F6F6F6' })
  ]))
}