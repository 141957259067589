
import {Options, Vue} from 'vue-class-component';
import AboutMe from './components/about-me/AboutMe.vue';
import Skills from './components/skills/Skills.vue';
import Works from './components/works/Works.vue';
import ProgramLanguages from './components/program-languages/ProgramLanguages.vue';
import Curve from '../header/components/Curve.vue';
import ISkillComponent from '../shared/interfaces/skill-component.interface';
import jsonDataWhatIDo from '@/assets/properties/what-i-do.json';
import jsonDataSpecialSkills from '@/assets/properties/special-skills.json';

@Options({
  components: {
    AboutMe,
    Skills,
    Works,
    Curve,
    ProgramLanguages,
  },
})
export default class Content extends Vue {
  public jsonDataWhatIDo: ISkillComponent = jsonDataWhatIDo;
  public jsonDataSpecialSkills: ISkillComponent = jsonDataSpecialSkills;

}
