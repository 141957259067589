import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19e1c737"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProgramLanguages__Wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "ProgramLanguages__List" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgramLanguage = _resolveComponent("ProgramLanguage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      class: "ProgramLanguages__Title",
      innerHTML: _ctx.dataProgramLanguages.title
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataProgramLanguages.items, (image, index) => {
        return (_openBlock(), _createBlock(_component_ProgramLanguage, {
          key: index,
          index: index,
          image: image
        }, null, 8, ["index", "image"]))
      }), 128))
    ])
  ]))
}