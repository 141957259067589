
import {Options, Vue} from 'vue-class-component';
import Curve from '@/components/header/components/Curve.vue';
import ParticlesJS from './components/ParticlesJS.vue';

@Options({
  components: {
    Curve,
    ParticlesJS,
  },
})
export default class Header extends Vue {
  public myName = 'andrea';
  public mySurname = 'campaci';
  public myTitle = 'Technical & Solution Architect';
}
