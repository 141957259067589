import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04bab26e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Footer__Wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "Footer__Socials" }
const _hoisted_5 = ["href", "target"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "Footer__Copyright" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogPolicy = _resolveComponent("DialogPolicy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      class: "Footer__Title",
      innerHTML: _ctx.footerData.title
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      class: "Footer__Text",
      innerHTML: _ctx.footerData.description
    }, null, 8, _hoisted_3),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerData.social, (social, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "Footer__Social",
          key: index
        }, [
          _createElementVNode("a", {
            href: social.link,
            target: social.target
          }, [
            _createElementVNode("img", {
              src: social.image
            }, null, 8, _hoisted_6)
          ], 8, _hoisted_5)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", {
        innerHTML: _ctx.footerData.copyright
      }, null, 8, _hoisted_8),
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalOpen = true))
      }, "Read policy"),
      _createElementVNode("div", null, [
        _createVNode(_component_DialogPolicy, {
          class: "Footer__DialogWrapper",
          active: _ctx.modalOpen,
          title: _ctx.footerData.dialog.title,
          content: _ctx.footerData.dialog.content,
          confirmText: _ctx.footerData.dialog.confirmText,
          onAgreed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalOpen = false))
        }, null, 8, ["active", "title", "content", "confirmText"])
      ])
    ])
  ]))
}