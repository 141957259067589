
import {Vue, Options} from 'vue-class-component';
import ProgramLanguage from './components/ProgramLanguage.vue';
import jsonData from '@/assets/properties/program-languages.json';
import IProgramLanguages from '../../../shared/interfaces/program-languages.interface';

@Options({
  components: {
    ProgramLanguage,
  },
})
export default class ProgramLanguages extends Vue {
  public dataProgramLanguages: IProgramLanguages = jsonData;
}
