import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b69d211"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "Button",
    href: _ctx.value.link,
    target: _ctx.value.target
  }, _toDisplayString(_ctx.value.text), 9, _hoisted_1))
}