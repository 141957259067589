import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2530bc06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Work__ImageWrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "Work__Content" }
const _hoisted_4 = { class: "Work__Title" }
const _hoisted_5 = { class: "Work__Subtitle" }
const _hoisted_6 = { class: "Work__Description" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_rjs = _resolveDirective("rjs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Work__Wrapper", { Work__Even: !_ctx.isOdd }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("img", {
        class: "Work__Image",
        src: _ctx.dataWork.image
      }, null, 8, _hoisted_2), [
        [_directive_rjs, 3]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.dataWork.title), 1),
      _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.dataWork.subtitle), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.dataWork.description), 1),
      (_ctx.dataWork.button)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_Button, {
              value: _ctx.dataWork.button
            }, null, 8, ["value"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}