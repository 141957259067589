
import {Options, Vue} from 'vue-class-component';
import Header from '@/components/header/Header.vue';
import Content from '@/components/content/Content.vue';
import Footer from '@/components/footer/Footer.vue';

@Options({
  components: {
      Header,
      Content,
      Footer,
  }
})
export default class Index extends Vue {}
